import React, { useState, useEffect } from 'react';

const CouponModal = ({ applyCoupon, closeModal }) => {
  const [coupons, setCoupons] = useState([]);

  // Cargar los cupones desde la base de datos (simulado)
  useEffect(() => {
    const fetchCoupons = async () => {
      // Supongamos que esta es tu API para obtener los cupones del usuario
      const response = await fetch(`/api/user/coupons`);
      const data = await response.json();
      setCoupons(data.coupons);
    };

    fetchCoupons();
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-black p-6 rounded-lg w-96 shadow-lg relative">
        <button onClick={closeModal} className="absolute top-2 right-2 text-white text-lg">
          &times;
        </button>
        <h2 className="text-xl text-orange-500 mb-4 text-center">Selecciona un Cupón</h2>
        <div className="space-y-4">
          {coupons.length > 0 ? (
            coupons.map((coupon) => (
              <div
                key={coupon.id}
                className="flex items-center justify-between bg-gray-700 p-3 rounded-lg cursor-pointer hover:bg-gray-600"
                onClick={() => applyCoupon(coupon)} // Aplicar cupón seleccionado
              >
                <div className="flex items-center space-x-4">
                  <img src={coupon.icon} alt="Cupón" className="w-8 h-8" />
                  <span className="text-white">{coupon.name}</span>
                </div>
                <span className="text-green-500">-{coupon.discount}%</span>
              </div>
            ))
          ) : (
            <p className="text-white">No tienes cupones disponibles</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponModal;
