  import React, { useEffect, useState } from 'react';
  import translations from './languages'; // Import the translations
  
  const BotStatus = ({ status, isOperating, lastOperationTime, language }) => {
    const t = translations[language];

   // Estado para la barra de progreso
  const [progress, setProgress] = useState(100);
  const [isCooldown, setIsCooldown] = useState(false);
    // Función para obtener el mensaje según el progreso
    const getProgressMessage = () => {
      if (progress >= 60) {
        return 'Reviewing previous progress';
      } else if (progress >= 45) {
        return 'Reviewing the market';
      } else if (progress >= 10) {
        return 'Getting a signal';
      } else if (progress >= 6) {
        return 'Making decision';
      } else if (progress >= 1) {
        return 'Entering the market';
      }
      return '';
    };

    useEffect(() => {
      let interval;
    
     // console.log(`Status: ${status}, Is Operating: ${isOperating}, Is Cooldown: ${isCooldown}, Last Operation Time: ${lastOperationTime}`);
    
      if (status === 'HOLD' && !isOperating && lastOperationTime) {
        setIsCooldown(true);
    
        const cooldownDuration = 310000; // 5 minutos y 10 segundos en milisegundos
        const timeSinceLastAction = Date.now() - lastOperationTime; // Tiempo transcurrido desde la última acción
        const timeRemaining = cooldownDuration - timeSinceLastAction; // Tiempo restante
    
        const initialProgress = Math.max((timeRemaining / cooldownDuration) * 100, 0);
        setProgress(initialProgress);
    
        //console.log(`Initial Progress: ${initialProgress}, Time Remaining: ${timeRemaining}`);
    
        // Si el tiempo restante es menor o igual a cero, se reinicia a 0
        if (timeRemaining <= 0) {
          setProgress(0);
          setIsCooldown(false);
          return;
        }
    
        // Configura el intervalo para actualizar el progreso
        interval = setInterval(() => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - lastOperationTime; // Tiempo transcurrido desde la última acción
          const newTimeRemaining = cooldownDuration - elapsedTime; // Tiempo restante actualizado
    
          const newProgress = Math.max((newTimeRemaining / cooldownDuration) * 100, 0);
          
          //console.log(`Current Time: ${currentTime}, Elapsed Time: ${elapsedTime}, New Time Remaining: ${newTimeRemaining}, New Progress: ${newProgress}`);
    
          if (newProgress <= 0) {
            clearInterval(interval);
            setIsCooldown(false);
            setProgress(0);
          } else {
            setProgress(newProgress);
          }
        }, 1000); // Actualiza cada segundo
      }
      else
      {
        clearInterval(interval);
        setIsCooldown(false);
        setProgress(0);
      }
    
      // Limpieza del intervalo al desmontar
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [status, isOperating, isCooldown, lastOperationTime]);
     
const getStatusIcon = () => {
      if (!isOperating) {
        return (
          <div className="bot-status-container bot-status-hold ">
  
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-200 -150 1050 950"><path fill="#ffffff" d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"/></svg>
          </div>
        );
      }
  
      switch (status) {
        case 'BUY':
          return (
            <div className="bot-status-container bot-status-trading">
  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-200 -150 1050 950"><path fill="#ffffff" d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"/></svg>
            </div>
          );
        case 'SELL':
          return (
            <div className="bot-status-container bot-status-trading">
  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-200 -150 1050 950"><path fill="#ffffff" d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"/></svg>
            </div>
          );
        default:
          return (
            <div className="bot-status-container bot-status-hold">
  
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-200 -150 1050 950"><path fill="#ffffff" d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z"/></svg>
            </div>
          );
      }
    };
  
    return (
      <div className="bot-status text-center z-10">
        {getStatusIcon()}
        <p className={`text-l progress-bar-text}`}> </p>
        <p className={`text-sm progress-bar-text}`}>
        {t.botstatus}:  
<span>
  {
    status === 'HOLD' && !isCooldown
      ? 'HOLD' 
      : !isOperating && (status === 'BUY' || status === 'SELL')
        ? ' Entering the market' 
        : isOperating && (status === 'BUY' || status === 'SELL')
          ? ' Trading' 
          : getProgressMessage()
  }
</span>
        </p>
        
        {isCooldown && (
          <div>
            <div className="progress-container-bot" style={{ width: '100%', height: '10px', backgroundColor: '#e0e0e0', borderRadius: '5px', marginTop: '10px' }}>     
              <div
                className="progress-bar-bot"
                style={{
                  width: `${progress}%`,
                  height: '100%',
                  borderRadius: '5px',
                  transition: 'width 0.5s',
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default BotStatus;
  