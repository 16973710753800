// languages.js
const translations = {
    en: {
      settings: "Settings",
      darkMode: "Dark Mode",
      fontSize: "Font Size",
      notifications: "Notifications",
      sounds: "Sounds",
      themeColor: "Theme Color",
      language: "Language",
      enable: "Enable",
      disable: "Disable",
      close: "Close",
      trading: "Trading",
      events: "Events",
      wallet: "Wallet",
      earn: "Earn",
      exit: "Exit",
      day: "Day",
      botstatus: "Bot Status",
      hold: "Hold",
      buy: "Buy",
      sell: "Sell",
      last3eventsh: "Last 3 Events",
      last100events: "Last 100 Events",
      currenttotal: "Current Total",
      gainloss: "Gain/Loss",
      tradingchart: "Trading Chart",
      balance: "Balance",
      notify: "Notify",
      logoutConfirm: 'Are you sure you want to exit?',
      yes: 'Yes',
      no: 'No',
      store: 'Store',
    },
    es: {
      settings: "Configuración",
      darkMode: "Modo Oscuro",
      fontSize: "Tamaño de Fuente",
      notifications: "Notificaciones",
      sounds: "Sonidos",
      themeColor: "Color del Tema",
      language: "Idioma",
      enable: "Habilitar",
      disable: "Deshabilitar",
      close: "Cerrar",
      trading: "Comercio",
      events: "Eventos",
      wallet: "Cartera",
      earn: "Ganar",
      exit: "Salir",
      day: "Día",
      botstatus: "Estado del Bot",
      hold: "Mantener",
      buy: "Comprar",
      sell: "Vender",
      last3eventsh: "Últimos 3 Eventos",
      last100events: "Últimos 100 Eventos",
      currenttotal: "Total Actual",
      gainloss: "Ganancia/Pérdida",
      tradingchart: "Gráfico de Comercio",
      balance: "Saldo",
      notify: "Notify",
      logoutConfirm: '¿Estás seguro de que deseas salir?',
      yes: 'Sí',
      no: 'No',
      store: 'Tienda',
    },
    fr: {
      settings: "Paramètres",
      darkMode: "Mode Sombre",
      fontSize: "Taille de Police",
      notifications: "Notifications",
      sounds: "Sons",
      themeColor: "Couleur du Thème",
      language: "Langue",
      enable: "Activer",
      disable: "Désactiver",
      close: "Fermer",
      trading: "Trading",
      events: "Événements",
      wallet: "Portefeuille",
      earn: "Gagner",
      exit: "Sortir",
      day: "Jour",
      botstatus: "État du Bot",
      hold: "Maintenir",
      buy: "Acheter",
      sell: "Vendre",
      last3eventsh: "Derniers 3 Événements",
      last100events: "Derniers 100 Événements",
      currenttotal: "Total Actuel",
      gainloss: "Gain/Perte",
      tradingchart: "Graphique de Trading",
      balance: "Solde",
      notify: "Notify",
    },
    pl: {
      settings: "Ustawienia",
      darkMode: "Tryb Ciemny",
      fontSize: "Rozmiar Czcionki",
      notifications: "Powiadomienia",
      sounds: "Dźwięki",
      themeColor: "Kolor Motywu",
      language: "Język",
      enable: "Włącz",
      disable: "Wyłącz",
      close: "Zamknij",
      trading: "Handel",
      events: "Wydarzenia",
      wallet: "Portfel",
      earn: "Zarabiać",
      exit: "Wyjście",
      day: "Dzień",
      botstatus: "Status Bota",
      hold: "Trzymaj",
      buy: "Kup",
      sell: "Sprzedaj",
      last3eventsh: "Ostatnie 3 Wydarzenia",
      last100events: "Ostatnie 100 Wydarzeń",
      currenttotal: "Obecny Łączny",
      gainloss: "Zysk/Strata",
      tradingchart: "Wykres Handlowy",
      balance: "Saldo",
      notify: "Notify",
    },
    pt: {
      settings: "Configurações",
      darkMode: "Modo Escuro",
      fontSize: "Tamanho da Fonte",
      notifications: "Notificações",
      sounds: "Sons",
      themeColor: "Cor do Tema",
      language: "Idioma",
      enable: "Habilitar",
      disable: "Desabilitar",
      close: "Fechar",
      trading: "Negociação",
      events: "Eventos",
      wallet: "Carteira",
      earn: "Ganhar",
      exit: "Sair",
      day: "Dia",
      botstatus: "Status do Bot",
      hold: "Manter",
      buy: "Comprar",
      sell: "Vender",
      last3eventsh: "Últimos 3 Eventos",
      last100events: "Últimos 100 Eventos",
      currenttotal: "Total Atual",
      gainloss: "Ganho/Perda",
      tradingchart: "Gráfico de Negociação",
      balance: "Saldo",
      notify: "Notify",
    },
    ja: {
      settings: "設定",
      darkMode: "ダークモード",
      fontSize: "フォントサイズ",
      notifications: "通知",
      sounds: "サウンド",
      themeColor: "テーマカラー",
      language: "言語",
      enable: "有効",
      disable: "無効",
      close: "閉じる",
      trading: "取引",
      events: "イベント",
      wallet: "ウォレット",
      earn: "稼ぐ",
      exit: "終了",
      day: "日",
      botstatus: "ボットのステータス",
      hold: "ホールド",
      buy: "購入",
      sell: "売却",
      last3eventsh: "最近の3つのイベント",
      last100events: "最近の100イベント",
      currenttotal: "現在の合計",
      gainloss: "利益/損失",
      tradingchart: "取引チャート",
      balance: "残高",
    },
    zh: {
      settings: "设置",
      darkMode: "暗黑模式",
      fontSize: "字体大小",
      notifications: "通知",
      sounds: "声音",
      themeColor: "主题颜色",
      language: "语言",
      enable: "启用",
      disable: "禁用",
      close: "关闭",
      trading: "交易",
      events: "事件",
      wallet: "钱包",
      earn: "赚取",
      exit: "退出",
      day: "天",
      botstatus: "机器人状态",
      hold: "持有",
      buy: "购买",
      sell: "出售",
      last3eventsh: "最近的3个事件",
      last100events: "最近的100个事件",
      currenttotal: "当前总计",
      gainloss: "收益/损失",
      tradingchart: "交易图表",
      balance: "余额",
    },
    ru: {
      settings: "Настройки",
      darkMode: "Темный Режим",
      fontSize: "Размер Шрифта",
      notifications: "Уведомления",
      sounds: "Звуки",
      themeColor: "Цвет Темы",
      language: "Язык",
      enable: "Включить",
      disable: "Отключить",
      close: "Закрыть",
      trading: "Торговля",
      events: "События",
      wallet: "Кошелек",
      earn: "Заработать",
      exit: "Выход",
      day: "День",
      botstatus: "Статус Бота",
      hold: "Удерживать",
      buy: "Покупать",
      sell: "Продавать",
      last3eventsh: "Последние 3 события",
      last100events: "Последние 100 событий",
      currenttotal: "Текущий Итог",
      gainloss: "Прибыль/Убыток",
      tradingchart: "График Торговли",
      balance: "Баланс",
    },
    ko: {
      settings: "설정",
      darkMode: "다크 모드",
      fontSize: "글꼴 크기",
      notifications: "알림",
      sounds: "사운드",
      themeColor: "테마 색상",
      language: "언어",
      enable: "사용",
      disable: "비활성화",
      close: "닫기",
      trading: "거래",
      events: "이벤트",
      wallet: "지갑",
      earn: "벌다",
      exit: "종료",
      day: "일",
      botstatus: "봇 상태",
      hold: "유지",
      buy: "구매",
      sell: "판매",
      last3eventsh: "최근 3개의 이벤트",
      last100events: "최근 100개의 이벤트",
      currenttotal: "현재 총계",
      gainloss: "수익/손실",
      tradingchart: "거래 차트",
      balance: "잔액",
    },
    ge: {
      settings: "Einstellungen",
      darkMode: "Dunkler Modus",
      fontSize: "Schriftgröße",
      notifications: "Benachrichtigungen",
      sounds: "Töne",
      themeColor: "Themenfarbe",
      language: "Sprache",
      enable: "Aktivieren",
      disable: "Deaktivieren",
      close: "Schließen",
      trading: "Handel",
      events: "Veranstaltungen",
      wallet: "Brieftasche",
      earn: "Verdienen",
      exit: "Beenden",
      day: "Tag",
      botstatus: "Bot-Status",
      hold: "Halten",
      buy: "Kaufen",
      sell: "Verkaufen",
      last3eventsh: "Letzte 3 Veranstaltungen",
      last100events: "Letzte 100 Veranstaltungen",
      currenttotal: "Aktuelle Gesamt",
      gainloss: "Gewinn/Verlust",
      tradingchart: "Handelsdiagramm",
      balance: "Saldo",
    },
    it: {
        settings: "Impostazioni",
        darkMode: "Modalità Scura",
        fontSize: "Dimensione del Carattere",
        notifications: "Notifiche",
        sounds: "Suoni",
        themeColor: "Colore del Tema",
        language: "Lingua",
        enable: "Abilita",
        disable: "Disabilita",
        close: "Chiudi",
        trading: "Commercio",
        events: "Eventi",
        wallet: "Portafoglio",
        earn: "Guadagnare",
        exit: "Esci",
        day: "Giorno",
        botstatus: "Stato del Bot",
        hold: "Mantieni",
        buy: "Compra",
        sell: "Vendi",
        last3eventsh: "Ultimi 3 Eventi",
        last100events: "Ultimi 100 Eventi",
        currenttotal: "Totale Attuale",
        gainloss: "Guadagno/Perdita",
        tradingchart: "Grafico di Commercio",
        balance: "Saldo",
    },
    ar: {
        settings: "الإعدادات",
        darkMode: "الوضع الداكن",
        fontSize: "حجم الخط",
        notifications: "الإشعارات",
        sounds: "الأصوات",
        themeColor: "لون السمة",
        language: "اللغة",
        enable: "تمكين",
        disable: "تعطيل",
        close: "إغلاق",
        trading: "التجارة",
        events: "الأحداث",
        wallet: "المحفظة",
        earn: "كسب",
        exit: "خروج",
        day: "يوم",
        botstatus: "حالة الروبوت",
        hold: "تمسك",
        buy: "شراء",
        sell: "بيع",
        last3eventsh: "آخر 3 أحداث",
        last100events: "آخر 100 حدث",
        currenttotal: "الإجمالي الحالي",
        gainloss: "الربح/الخسارة",
        tradingchart: "مخطط التداول",
        balance: "الرصيد",
    },
    hi: {
        settings: "सेटिंग्स",
        darkMode: "डार्क मोड",
        fontSize: "फॉन्ट आकार",
        notifications: "सूचनाएं",
        sounds: "ध्वनियाँ",
        themeColor: "थीम रंग",
        language: "भाषा",
        enable: "सक्षम करें",
        disable: "अक्षम करें",
        close: "बंद करें",
        trading: "व्यापार",
        events: "घटनाएँ",
        wallet: "वॉलेट",
        earn: "कमाएँ",
        exit: "बाहर निकलें",
        day: "दिन",
        botstatus: "बॉट स्थिति",
        hold: "रोकें",
        buy: "खरीदें",
        sell: "बेचें",
        last3eventsh: "पिछली 3 घटनाएँ",
        last100events: "पिछली 100 घटनाएँ",
        currenttotal: "वर्तमान कुल",
        gainloss: "लाभ/हानि",
        tradingchart: "व्यापार चार्ट",
        balance: "बैलेंस",
      },
    tr: {
        settings: "Ayarlar",
        darkMode: "Karanlık Mod",
        fontSize: "Yazı Boyutu",
        notifications: "Bildirimler",
        sounds: "Sesler",
        themeColor: "Tema Rengi",
        language: "Dil",
        enable: "Etkinleştir",
        disable: "Devre Dışı Bırak",
        close: "Kapat",
        trading: "Ticaret",
        events: "Etkinlikler",
        wallet: "Cüzdan",
        earn: "Kazanç",
        exit: "Çıkış",
        day: "Gün",
        botstatus: "Bot Durumu",
        hold: "Tut",
        buy: "Satın Al",
        sell: "Sat",
        last3eventsh: "Son 3 Etkinlik",
        last100events: "Son 100 Etkinlik",
        currenttotal: "Mevcut Toplam",
        gainloss: "Kazanç/Kayıp",
        tradingchart: "Ticaret Grafiği",
        balance: "Bakiye",
      },
    nl: {
        settings: "Instellingen",
        darkMode: "Donkere Modus",
        fontSize: "Lettergrootte",
        notifications: "Meldingen",
        sounds: "Geluiden",
        themeColor: "Themakleur",
        language: "Taal",
        enable: "Inschakelen",
        disable: "Uitschakelen",
        close: "Sluiten",
        trading: "Handelen",
        events: "Evenementen",
        wallet: "Portemonnee",
        earn: "Verdienen",
        exit: "Afsluiten",
        day: "Dag",
        botstatus: "Botstatus",
        hold: "Vasthouden",
        buy: "Kopen",
        sell: "Verkopen",
        last3eventsh: "Laatste 3 Evenementen",
        last100events: "Laatste 100 Evenementen",
        currenttotal: "Huidige Totaal",
        gainloss: "Winst/Verlies",
        tradingchart: "Handelsgrafiek",
        balance: "Balans",
      },
    sv: {
        settings: "Inställningar",
        darkMode: "Mörkt Läge",
        fontSize: "Textstorlek",
        notifications: "Meddelanden",
        sounds: "Ljud",
        themeColor: "Temafärg",
        language: "Språk",
        enable: "Aktivera",
        disable: "Inaktivera",
        close: "Stäng",
        trading: "Handel",
        events: "Händelser",
        wallet: "Plånbok",
        earn: "Tjäna",
        exit: "Avsluta",
        day: "Dag",
        botstatus: "Botstatus",
        hold: "Håll",
        buy: "Köp",
        sell: "Sälj",
        last3eventsh: "Senaste 3 Händelser",
        last100events: "Senaste 100 Händelser",
        currenttotal: "Nuvarande Total",
        gainloss: "Vinst/Förlust",
        tradingchart: "Handelsdiagram",
        balance: "Balans",
      },
    he: {
        settings: "הגדרות",
        darkMode: "מצב כהה",
        fontSize: "גודל גופן",
        notifications: "התראות",
        sounds: "צלילים",
        themeColor: "צבע נושא",
        language: "שפה",
        enable: "הפעל",
        disable: "השבת",
        close: "סגור",
        trading: "מסחר",
        events: "אירועים",
        wallet: "ארנק",
        earn: "להרוויח",
        exit: "יציאה",
        day: "יום",
        botstatus: "סטטוס הבוט",
        hold: "להחזיק",
        buy: "לקנות",
        sell: "למכור",
        last3eventsh: "3 האירועים האחרונים",
        last100events: "100 האירועים האחרונים",
        currenttotal: "סך הכל הנוכחי",
        gainloss: "רווח/הפסד",
        tradingchart: "גרף מסחר",
        balance: "איזון",
      }
    // You can add more languages here
  };
  
  export default translations;
  