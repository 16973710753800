import React, { useEffect, useState } from 'react';
import translations from './languages'; // Import the translations

const ConfigModal = ({
  isOpen,
  closeModal,
  darkMode,
  toggleDarkMode,
  fontSize,
  changeFontSize,
  notifications,
  toggleNotifications,
  sounds,
  toggleSounds,
  themeColor,
  changeThemeColor,
  language,
  changeLanguage,
  telegram_id // Add the userId to identify the user in the database
}) => {
  const [isClosing, setIsClosing] = useState(false);

  // Fetch the translations based on the selected language
  const t = translations[language];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300); // Duración de la animación
  };

  // Function to save the settings to the database
  const saveSettings = async (settings) => {
    try {
      console.log("Enviando configuración:", { telegram_id, ...settings }); // Verifica lo que estás enviando

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/config`, {  // Usa la variable de entorno
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegram_id, // Include userId for backend identification
          ...settings, // Spread the settings (darkMode, notifications, etc.)
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  // Handle changes and save settings to the backend
  const handleToggleDarkMode = () => {
    toggleDarkMode();
    saveSettings({ darkMode: !darkMode });
  };

  const handleToggleNotifications = () => {
    toggleNotifications();
    saveSettings({ notifications: !notifications });
  };

  const handleToggleSounds = () => {
    toggleSounds();
    saveSettings({ sounds: !sounds });
  };

  const handleChangeThemeColor = (color) => {
    changeThemeColor(color);
    saveSettings({ themeColor: color });
  };

  const handleChangeLanguage = (lang) => {
    changeLanguage(lang);
    saveSettings({ language: lang });
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
        <div
    className={`modal-content bg-black p-6 shadow-lg shadow-neon-top rounded-lg relative transition-transform duration-300 ease-in-out ${
      isClosing ? 'animate-slideDown' : 'animate-slideUp'
    }`}
    style={{ maxHeight: '90vh', width: '100%', maxWidth: '600px' }}
  >
    {/* Hacer que el header esté completamente fijo */}
    <div className="modal-header flex justify-between items-center mb-4 sticky bg-black p-6 z-10" style={{ position: 'sticky', top: -30 }}>
      {/* Título centrado */}
      <h2 className="text-xl text-orange-500 text-center  w-full">{t.settings}</h2>

      {/* Botón de cerrar en la esquina superior derecha */}
      <button
        onClick={handleClose}
        className="absolute top-2 right-2 text-gray-400 hover:text-white"
      >
        <i className="fas fa-times text-xl"></i>
      </button>
    </div>

        <div className="space-y-6">
          {/* Configuración de Modo Oscuro */}
          <div className="flex items-center justify-between py-3">
            <label className="mr-4">{t.darkMode}:</label>
            <label className="switch">
              <input type="checkbox" checked={darkMode} onChange={handleToggleDarkMode} />
              <span className="slider round"></span>
            </label>
          </div>

          {/* Configuración de Notificaciones */}
          <div className="flex items-center justify-between py-3">
            <label className="mr-4">{t.notifications}:</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={notifications}
                onChange={handleToggleNotifications}
              />
              <span className="slider round"></span>
            </label>
          </div>

          {/* Configuración de Sonidos */}
          <div className="flex items-center justify-between py-3">
            <label className="mr-4">{t.sounds}:</label>
            <label className="switch">
              <input type="checkbox" checked={sounds} onChange={handleToggleSounds} />
              <span className="slider round"></span>
            </label>
          </div>

          {/* Configuración de Tema de Color */}
          <div className="flex items-center justify-between py-3">
            <label className="mr-4">{t.themeColor}:</label>
            <select
              value={themeColor}
              onChange={(e) => handleChangeThemeColor(e.target.value)}
              className="select-setting bg-gray-200 px-4 py-2 rounded-lg"
            >
              <option value="orange">Orange</option>
              <option value="blue">Blue</option>
              <option value="red">Red</option>
              <option value="green">Green</option>
              <option value="pink">Pink</option>
            </select>
          </div>

          {/* Configuración de Idioma */}
          <div className="flex items-center justify-between py-3">
            <label className="mr-4">{t.language}:</label>
            <select
              value={language}
              onChange={(e) => handleChangeLanguage(e.target.value)}
              className="select-setting bg-gray-200 px-4 py-2 rounded-lg"
            >
               <option value="en">English</option>
              <option value="es">Spanish</option>
              <option value="fr">French</option>
              <option value="de">German</option>
              <option value="pl">Polish</option>
              <option value="pt">Portuguese</option>
              <option value="ja">Japanese (日本語)</option>
              <option value="zh">Chinese (中文)</option>
              <option value="ru">Russian (Русский)</option>
              <option value="ko">Korean (한국어)</option>
              <option value="ar">Arabic (العربية)</option>
              <option value="hi">Hindi (हिन्दी)</option>
              <option value="tr">Turkish (Türkçe)</option>
              <option value="nl">Dutch (Nederlands)</option>
              <option value="sv">Swedish (Svenska)</option>
              <option value="he">Hebrew (עברית)</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigModal;
