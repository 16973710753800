import React from 'react';
import translations from './languages'; // Import the translations
// Función para calcular la suma del profitPercentage dentro de un rango de días
export const calculateProfitSum = (events, days) => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setDate(currentDate.getDate() - days); // Restar los días para obtener el rango

  // Filtrar los eventos que están dentro del rango de días
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date);
    return eventDate >= pastDate && eventDate <= currentDate;
  });

  // Sumar el profitPercentage de los eventos filtrados
  return filteredEvents.reduce((sum, event) => sum + parseFloat(event.profitPercentage), 0);
};
const Events = ({ eventList, language }) => {

  const t = translations[language];
  // Obtener los últimos 3 eventos
  const last3Events = eventList.slice(0, 3);

  // Componente para renderizar una lista de eventos
  const EventList = ({ events }) => (
    <ul>
      {events.map((event, index) => {
        const profitPercentage = parseFloat(event.profitPercentage);

        // Determinar el ícono según el porcentaje de ganancia/pérdida
        let icon;
        let profitColor;

        if (profitPercentage >= 0.01) {
          icon = <i className="fas fa-arrow-up"></i>; // Flecha arriba para ganancias
          profitColor = 'text-green-500 shadow-neon-top-green'; // Verde para ganancias
        } else if (profitPercentage <= -0.01) {
          icon = <i className="fas fa-arrow-down"></i>; // Flecha abajo para pérdidas
          profitColor = 'text-red-500 shadow-neon-bottom-red'; // Rojo para pérdidas
        } else {
          icon = <i className="fas fa-minus"></i>; // Guión para valores cercanos a 0
          profitColor = 'text-gray-500'; // Gris para neutro
        }

        return (
      
          <li key={index} className="content-event">
            {/* Icono de Fecha */}
            <div className="flex items-center text-sm text-gray-400">
              <i className="fas fa-calendar-alt"></i>
              <p>{new Date(event.date).toLocaleString()}</p>
            </div>

            {/* Icono de Precio */}
            <div className="flex items-center text-sm text-gray-400">
              <i className="fas fa-dollar-sign"></i>
              <p>${Intl.NumberFormat('en', { style: 'decimal' }).format(event.price.toFixed(2))}</p>
            </div>

            {/* Ganancia/Pérdida */}
            <div className={`profit flex items-center ${profitColor}`}>
              {icon}
              <p className="ml-1">{profitPercentage.toFixed(2)}%</p>
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div>
      {/* Sección de los últimos 3 eventos */}
      <div className="last-3-events">
        <h2>{t.last3eventsh}</h2>
        <EventList events={last3Events} />
      </div>
    </div>
  );
};

export default Events;
