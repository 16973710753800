import React, { createContext, useState, useContext } from 'react';

const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const [tooltipMessages, setTooltipMessages] = useState([]);

  const addTooltipMessage = (message, type) => {
    const newMessage = { message, type }; // Guardar el tipo junto con el mensaje
    setTooltipMessages((prevMessages) => [...prevMessages, newMessage]);

    setTimeout(() => {
      setTooltipMessages((prevMessages) =>
        prevMessages.filter((msg) => msg !== newMessage)
      );
    }, 3000);
  };

  const setTooltipAlert = (message, type = 'alert') => addTooltipMessage(message, type);


  return (
    <TooltipContext.Provider
      value={{ setTooltipAlert, tooltipMessages }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => useContext(TooltipContext);
