import React, { useState, useEffect, useRef  } from 'react';
import { Buffer } from 'buffer';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import Header from './components/Header.js';
import Footer from './components/Footer.js';
import MainContent from './components/MainContent.js';
import ConfigModal from './components/ConfigModal.js';
import TradingViewModal from './components/TradingViewModal.js';
import Spinner from './components/Spinner.js';
import EventModal from './components/EventModal.js';
import UserWalletModal from './components/Modals/UserWallet.js'; 
import NotificationModal from './components/NotificationModal.js'; 
import NetworkSelector from './components/NetworkSelector.js';
import { TooltipProvider } from './components/ToolTips/TooltipContext.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './dist/output.css'; 
import '@rainbow-me/rainbowkit/styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { config } from './wallets/wagmiConfig.js'
const queryClient = new QueryClient()



// Asegúrate de que esté disponible globalmente
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}
const App = () => {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(true);  // Estado para controlar el spinner
  const [loadingStep, setLoadingStep] = useState(0);  // Estado para controlar el spinner
  const [darkMode, setDarkMode] = useState(false);
  const [fontSize, setFontSize] = useState('medium');
  const [notifications, setNotifications] = useState(true);
  const [sounds, setSounds] = useState(true);
  const [themeColor, setThemeColor] = useState('orange');
  const [language, setLanguage] = useState('en');
  const [isBotOperating, setIsBotOperating] = useState(false); // Si el bot está operando o no
  const [userData, setUserData] = useState({ firstName: '', lastName: '', balance: 0 , walletAddress: '' });
  const [userInfo, setUserInfo] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const isUserNetworkChanged = useRef(false); 
  const [walletAddress, setWalletAddress] = useState(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  //User Data Sync 2.1
  const [UserBalance, setUserBalance] = useState(0);
  const [UserisWallet, setUserIsWallet] = useState(false);
  const [UserGainPercentage, setUserGainPercentage] = useState('0.00');
  const [UserwalletAddress, setUserWalletAddress] = useState('0x');
  const [UserCommissionRate, seUserCommissionRate] = useState('0.10');
  
  

useEffect(() => {
    const controller = new AbortController();
    let timeoutIds = [];
    let intervalIds = [];

    const verifyOrCreateUser = (user) => {
      setLoadingStep(1);
      fetch(`/api/user/verify?telegram_id=${user.telegram_id}&first_name=${user.first_name}&last_name=${user.last_name}&username=${user.username}`, 
        { signal: controller.signal })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setLoadingStep(-1); 
          } else {
            setUserData(data);
            setLoadingStep(2); 
            applyUserSettings(data.themeColor, data.fontSize, data.darkMode, data.language); 
          }
        })
        .catch((error) => {
          setLoadingStep(-1); 
        });
    };

    const applyUserSettings = (themeColor, fontSize, darkMode, language) => {
      setLoadingStep(3); 

      if (themeColor) document.documentElement.setAttribute('data-theme', themeColor);
      if (fontSize) {
        document.documentElement.style.fontSize =
          fontSize === 'small' ? '14px' : fontSize === 'medium' ? '16px' : '18px';
      }

      document.documentElement.setAttribute('data-dark-mode', darkMode ? 'true' : 'false');
      if (language) document.documentElement.setAttribute('lang', language);

      const steps = [
        { step: 1, duration: Math.floor(Math.random() * (800 - 600 + 1) + 600) },
        { step: 2, duration: Math.floor(Math.random() * (850 - 650 + 1) + 650) },
        { step: 3, duration: Math.floor(Math.random() * (900 - 700 + 1) + 700) },
        { step: 4, duration: Math.floor(Math.random() * (750 - 600 + 1) + 600) },
      ];

      steps.forEach(({ step, duration }, index) => {
        const startPercentage = (index) * (100 / steps.length);
        const endPercentage = (index + 1) * (100 / steps.length);
        const increment = (endPercentage - startPercentage) / (duration / 100);

        const timeoutId = setTimeout(() => {
          let currentPercentage = startPercentage;
          
          const intervalId = setInterval(() => {
            currentPercentage += increment;
    
            if (currentPercentage >= endPercentage) {
              clearInterval(intervalId);
              setLoadingStep(step);
    
              if (step === 4 && currentPercentage >= 100) {
                setTimeout(() => {
                  setLoading(false);
                }, 3000); 
              }
            } else {
              setPercentage(Math.min(currentPercentage, 100));
            }
          }, 100);

          intervalIds.push(intervalId); // Almacenar el ID del intervalo
        }, steps.slice(0, index + 1).reduce((acc, s) => acc + s.duration, 0));

        timeoutIds.push(timeoutId); // Almacenar el ID del timeout
      });
    };

    const urlParams = new URLSearchParams(window.location.search);
    const telegramIdParam = urlParams.get('telegram_id');
  
    if (telegramIdParam) {
      const simulatedUser = {
        telegram_id: telegramIdParam,
        first_name: 'Simulado',
        last_name: 'Usuario',
        username: 'Test',
      };
      setUserInfo(simulatedUser);
      verifyOrCreateUser(simulatedUser);
    } else {
      window.Telegram.WebApp.ready();
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.id) {
        const user = {
          telegram_id: telegramUser.id,
          first_name: telegramUser.first_name || '',
          last_name: telegramUser.last_name || '',
          username: telegramUser.username || '',
        };
        setUserInfo(user);
        verifyOrCreateUser(user);
      } else {
        setLoadingStep(-1);
      }
    }

    return () => {
      controller.abort();
      timeoutIds.forEach(clearTimeout);
      intervalIds.forEach(clearInterval);
    }; // Limpieza de abortController, timeouts e intervals
}, []);

useEffect(() => {
  const currentTheme = document.documentElement.getAttribute('data-theme');
  const currentFontSize = document.documentElement.style.fontSize;
  const currentDarkMode = document.documentElement.getAttribute('data-dark-mode');
  const currentLanguage = document.documentElement.getAttribute('lang');

  const newTheme = themeColor;
  const newFontSize = fontSize === 'small' ? '14px' : fontSize === 'medium' ? '16px' : '18px';
  const newDarkMode = darkMode ? 'true' : 'false';
  const newLanguage = language;

  if (currentTheme !== newTheme) {
    document.documentElement.setAttribute('data-theme', newTheme);
  }
  if (currentFontSize !== newFontSize) {
    document.documentElement.style.fontSize = newFontSize;
  }
  if (currentDarkMode !== newDarkMode) {
    document.documentElement.setAttribute('data-dark-mode', newDarkMode);
  }
  if (currentLanguage !== newLanguage) {
    document.documentElement.setAttribute('lang', newLanguage);
  }
}, [themeColor, fontSize, darkMode, language]);


  // Establecer la red seleccionada al cargar los datos del usuario
useEffect(() => {
      setSelectedNetwork(userData.user_network);
      setUserBalance(userData.balance);
      setUserIsWallet(userData.isWallet);
      setUserGainPercentage(userData.gain_loss);   
      setUserWalletAddress(userData.wallet_Address); 
      seUserCommissionRate(userData.commission_rate);
  }, [userData]);

// Manejar el cambio de red por parte del usuario
const handleNetworkChange = (network) => {
  isUserNetworkChanged.current = true; // Marcar que el usuario cambió la red
  setSelectedNetwork(network);
  console.log(`Red seleccionada manualmente: ${network}`);
};

const UserWalletAddress = (wallet_Address) => {
  isUserNetworkChanged.current = true; // Marcar que el usuario cambió la red
  setWalletAddress(wallet_Address);

  console.log(`Conectado con exito a la billetera: ${wallet_Address}`);
};

const UserIsWallet = (is_Wallet) => {
  isUserNetworkChanged.current = true; // Marcar que el usuario cambió la red
  setIsWalletConnected(is_Wallet);
 
};
// Guardar la preferencia de red en la base de datos
const saveNetworkPreference = (network) => {
  const controller = new AbortController();
  const signal = controller.signal;

  // Comprobar si la red ya está guardada en localStorage
  const storedNetwork = localStorage.getItem('selectedNetwork');

  if (storedNetwork !== network) {
    // Si la red en localStorage es diferente, sobrescribir el valor
    localStorage.setItem('selectedNetwork', network); // Guardar la red actual en localStorage
    console.log(`Nueva red guardada en localStorage: ${network}`);

    // Establecer un temporizador para hacer la petición al backend en 60 segundos
    const timer = setTimeout(() => {
      fetch('/api/saveNetworkPreference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ telegramId: userData?.telegram_id, network }),
        signal, // Pasar el signal del AbortController
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('Preferencia de red guardada:', data);
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Petición abortada');
          } else {
            console.error('Error al guardar la preferencia de red:', error);
          }
        });
    }, 2000); // 2 segundos

    // Función de limpieza para abortar la petición
    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  } else {
    console.log(`La red ${network} ya estaba guardada en localStorage, no es necesario actualizar.`);
  }
};

useEffect(() => {
  const controller = new AbortController();
  const signal = controller.signal;

  const fetchBotData = async (signal) => {
    try {
      const [botStatusResponse] = await Promise.all([
        fetch('/api/bot-status', { signal }),
      ]);

      const botStatusData = await botStatusResponse.json();
      setIsBotOperating(botStatusData.isOperating);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Solicitud abortada');
      } else {
        console.error('Error al obtener los datos del bot y eventos:', error);
      }
    }
  };

  fetchBotData(signal); // Fetch inicial

  const interval = setInterval(() => {
    fetchBotData(signal); // Refrescar cada 2 segundos
  }, 2000);

  return () => {
    clearInterval(interval);
    controller.abort(); // Limpiar al desmontar o cuando cambia el estado de los modales
  };
}, []);


  const toggleDarkMode = () => setDarkMode(!darkMode);
  const openWalletModal = () => setIsWalletModalOpen(true);
  const closeWalletModal = () => setIsWalletModalOpen(false);
  const closeUserModal = () => setIsWalletModalOpen(false); 
  const changeFontSize = (size) => setFontSize(size);
  const toggleNotifications = () => setNotifications(!notifications);
  const toggleSounds = () => setSounds(!sounds);
  const changeThemeColor = (color) => setThemeColor(color);
  const changeLanguage = (lang) => setLanguage(lang);

  
  const toggleUserWalletModal = () => {
    setActiveModal((prevState) => (prevState === 'userwallet' ? null : 'userwallet'));
  };
  
  const toggleConfigPanel = () => {
    setActiveModal((prevState) => (prevState === 'config' ? null : 'config'));
  };
  
  const toggleTradingView = () => {
    setActiveModal((prevState) => (prevState === 'trading' ? null : 'trading'));
  };

  const toggleEventModal = () => {
    setActiveModal((prevState) => (prevState === 'event' ? null : 'event'));
  };
  
  const toggleNotificationModal = () => {
    setActiveModal((prevState) => (prevState === 'notification' ? null : 'notification'));
  };
  
  const isModalOpen = activeModal !== null;
  
  return (
    
    <div id="app-container" className={`min-h-screen flex flex-col ${darkMode ? 'dark' : ''}`}>
      {loading ? (
       <Spinner percentage={percentage} />
      ) : (
        <>
         <TonConnectUIProvider manifestUrl="https://td.erons.site/tonconnect/manifest.json">      
         <WagmiProvider config={config}>
         <QueryClientProvider client={queryClient}> 
         <TooltipProvider>
          <Header 
            firstName={userData.firstName} 
            lastName={userData.lastName} 
            telegramUserId={userInfo?.telegram_id} 
            toggleConfigPanel={toggleConfigPanel}
            toggleUserWalletModal={toggleUserWalletModal}  
            user_Network={selectedNetwork}  
            balance={UserBalance}
            gain_loss={UserGainPercentage}
            language={language}
            isBotOperating={isBotOperating}       
          />

          <MainContent language={language} isModalOpen={isModalOpen} />


          {activeModal === 'config' && 
          <ConfigModal
            isOpen={true}
            closeModal={toggleConfigPanel}
            darkMode={darkMode}
            toggleDarkMode={toggleDarkMode}
            fontSize={fontSize}
            changeFontSize={changeFontSize}
            notifications={notifications}
            toggleNotifications={toggleNotifications}
            sounds={sounds}
            toggleSounds={toggleSounds}
            themeColor={themeColor}
            changeThemeColor={changeThemeColor}
            language={language}
            changeLanguage={changeLanguage}
            telegram_id={userInfo?.telegram_id}
          />}
          
          {activeModal === 'trading' &&         
          <TradingViewModal
            isOpen={true}
            closeModal={toggleTradingView}
            language={language}
          />}       
        
          {activeModal === 'userwallet' && 
          <UserWalletModal 
          isOpen={true} 
          closeModal={toggleUserWalletModal} 
          selectedNetwork={selectedNetwork}
          handleNetworkChange={handleNetworkChange}
          saveNetworkPreference={saveNetworkPreference} 
          UserisWallet={UserisWallet}
          currentbalance={UserBalance}
          CommissionRate={UserCommissionRate}
          isBotOperating={isBotOperating}
          telegramUserId={userInfo?.telegram_id}
          language={language}
          />
              
          }          
                
         {activeModal === 'event' &&   
         <EventModal
            isOpen={true}
            closeModal={toggleEventModal}
            language={language}
          />}
          
          
          {activeModal === 'notification' && 
          <NotificationModal
           isOpen={true}
           closeModal={toggleNotificationModal}
           telegramUserId={userInfo?.telegram_id}
           language={language}
         />}
          
   
          <Footer 
           toggleTradingView={toggleTradingView}
           toggleUserWalletModal={toggleUserWalletModal} 
           toggleEventModal={toggleEventModal}
           toggleNotificationModal={toggleNotificationModal}
           language={language}
          />
           </TooltipProvider>
           </QueryClientProvider> 
           </WagmiProvider>
          </TonConnectUIProvider>
          </>
          
      )}
    </div>
  );
};

export default App;
