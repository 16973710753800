import React from 'react';
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';

export function Account() {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName });

  return (
    <div style={styles.container}>
      {ensAvatar ? (
        <img alt="ENS Avatar" src={ensAvatar} style={styles.avatar} />
      ) : (
        <div style={styles.placeholderAvatar} />
      )}
      {address && (
        <div style={styles.address}>
          {ensName
            ? `${ensName} (${address.slice(0, 6)}...${address.slice(-4)})`
            : `${address.slice(0, 8)}...${address.slice(-8)}`}
        </div>
      )}
      <button
        onClick={() => disconnect()}
        style={styles.button}
        aria-label="Disconnect wallet"
      >
        Disconnect
      </button>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    backdropFilter: 'blur(20px)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '14px',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    padding: '10px', // Añadir un poco de padding para mejorar la estética
    textAlign: 'center', // Centrar el contenido dentro del contenedor
  },
  avatar: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: 'rgba(39, 42, 42, 0.66)', // Color de fondo para el avatar
    marginBottom: '10px',
  },
  placeholderAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'rgba(39, 42, 42, 0.66)', // Color de fondo para el avatar
    marginBottom: '10px',
  },
  address: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  button: {
    width: '100%',
    backgroundColor: '#4CAF50',
    backdropFilter: 'blur(20px)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '14px',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer', // Añadir cursor pointer para mejor experiencia de usuario
  },
};
