import React from 'react';

const Spinner = ({ percentage }) => {
  return (
    <div className="spinloader flex flex-col justify-center items-center h-screen space-y-4">
      {/* Círculo de spin grande */}
      <div className="spinner-container">
        {/* Círculo externo */}
        <div className="spinner-external"></div>

        {/* Círculo interno */} 
        <div className="spinner-internal"></div>
      </div>

      {/* Texto "Cargando" con porcentaje 
      <div className="text-white text-lg font-semibold">
      <p>Loading... {percentage.toFixed(1)}%</p>
      </div>*/}

      {/* Barra de progreso horizontal */}
      <div className="progress-bar-container">
        <div
          className="progress-bar" id="progressBar"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Spinner;
