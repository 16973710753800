export const COMMISSION_ADDRESS = 'TM9EjWzG74b5CfxUCTHChmx49mvDNaHYUH'
export const COMMISSION_NETWORK = 'TRX'

export const BOT_ADDRESS_USDT_TON = 'EQDD8dqOzaj4zUK6ziJOo_G2lx6qf1TEktTRkFJ7T1c_fPQb'
export const TON_MEMO = '18226619'
export const BOT_ADDRESS_USDT_TRC20 = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
export const BOT_ADDRESS_USDT_BSC = '0x64a245f048087afad2adae22f336bdb8685850ca'
export const BOT_ADDRESS_USDT_ERC20 = '0x64a245f048087afad2adae22f336bdb8685850ca'
export const BOT_ADDRESS_USDT_POLYGON = '0x64a245f048087afad2adae22f336bdb8685850ca'

