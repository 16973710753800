import React, { useEffect, useState } from 'react';
import translations from './languages'; // Import the translations

const NotificationModal = ({ isOpen, closeModal, telegramUserId, language }) => {
    const [notifications, setNotifications] = useState([]);
    const [isClosing, setIsClosing] = useState(false);
    const t = translations[language];

    // Obtener notificaciones del usuario desde la base de datos
    useEffect(() => {
        if (isOpen) {
          fetch(`/api/get-notifications/${telegramUserId}`)
            .then(response => response.json())
            .then(data => setNotifications(data.notifications || []))
            .catch(error => console.error('Error al obtener las notificaciones:', error));
        }
      }, [isOpen, telegramUserId]);

    // Función para marcar todas las notificaciones como leídas
    const markAsRead = async () => {
        try {
          await fetch('/api/mark-as-read', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_id: telegramUserId })
          });
          closeModal(); // Cierra el modal tras marcar como leído
        } catch (error) {
          console.error('Error al marcar notificaciones como leídas:', error);
        }
      };

    // Control para cerrar el modal con animación
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
          closeModal();
          setIsClosing(false);
        }, 300); // Duración de la animación de cierre
      };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div
          className={`modal-content bg-black p-6 shadow-lg shadow-neon-top rounded-lg ${
            isClosing ? 'animate-slideDown' : 'animate-slideUp'
          }`}
        >
          {/* Botón de cerrar en la esquina superior derecha */}
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <i className="fas fa-times text-xl"></i>
          </button>

          {/* Título centrado */}
          <h2 className="text-xl text-orange-500 mb-4 text-center">{t.notifications}</h2>

          {/* Lista de notificaciones */}
          <ul className="text-white">
            {notifications.length > 0 ? (
              notifications.map(notification => (
                <li key={notification.id} className="mb-2">
                  {notification.mensaje} <br />
                  <small>{new Date(notification.created_at).toLocaleString()}</small>
                </li>
              ))
            ) : (
              <li>{t.noNewNotifications}</li> // Mensaje cuando no hay notificaciones
            )}
          </ul>

          {/* Botón para marcar como leído */}
          <button
            onClick={markAsRead}
            className="mt-4 bg-orange-500 px-4 py-2 rounded-lg text-white"
          >
            {t.markAsReadAndClose}
          </button>
        </div>
      </div>
    );
};

export default NotificationModal;
