import React, { useEffect, useState, useRef } from 'react';

const TradingViewModal = ({ isOpen, closeModal }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    console.log("Modal isOpen:", isOpen); // Verificar si el modal está abierto

    if (isOpen && window.TradingView) {
      const checkContainer = setInterval(() => {
        if (chartContainerRef.current) {
          console.log("Initializing TradingView widget...");

          new window.TradingView.widget({
            container_id: chartContainerRef.current.id, // Asegurarse de usar el id del contenedor
            width: '100%',
            height: 400,
            symbol: 'BINANCE:BTCUSDT',
            interval: '1',
            timezone: 'Etc/UTC',
            theme: 'dark',
            style: '1',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            withdateranges: false,
            hide_side_toolbar: true,
            allow_symbol_change: false,
            save_image: false,
            studies: ['MACD@tv-basicstudies'],
            onready: () => {
              console.log("TradingView chart is ready!");
              setIsLoading(false);
            }
          });

          clearInterval(checkContainer); // Detener la verificación una vez que el contenedor exista
        } else {
          console.log("Chart container reference is null, waiting...");
        }
      }, 100); // Verificar cada 100ms si el contenedor está disponible

    } else if (!window.TradingView) {
      console.error("TradingView script not loaded.");
    }

    return () => {
      if (!isOpen && chartContainerRef.current) {
        console.log("Cleaning up TradingView widget...");
        chartContainerRef.current.innerHTML = ''; // Limpiar el gráfico cuando el modal se cierre
      }
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300);
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
      <div
        className={`modal-content bg-black p-6 shadow-lg shadow-neon-top rounded-lg relative transition-transform duration-300 ease-in-out ${
          isClosing ? 'animate-slideDown' : 'animate-slideUp'
        }`}
        style={{ maxHeight: '90vh', width: '100%', maxWidth: '600px' }}
      >
        {/* Header fijo */}
        <div className="modal-header flex justify-between items-center mb-4 sticky bg-black p-6 z-10" style={{ position: 'sticky', top: -30 }}>
          <h2 className="text-xl text-orange-500 text-center w-full">Trading Chart</h2>
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <i className="fas fa-times text-xl"></i>
          </button>
        </div>

        <div className="mb-4 overflow-y-auto" style={{ maxHeight: '50vh' }}></div>

        {/* Loader mientras se carga el gráfico */}

          {/* Contenedor del gráfico TradingView */}
          <div id="tradingview_chart" ref={chartContainerRef} style={{ width: '100%', height: '400px' }}></div>
        
      </div>
    </div>
  );
};

export default TradingViewModal;
