import React from 'react';

const Tooltip = () => {
  const messages = [
    "User:4578*** ha retirado 1.200 USDT",
    "User:1245 ha retirado 1.200 USDT",
    "User:4587*** ha ingresado al mercado",
    "User:7634 ha salido del mercado",
    "User:8901*** ha depositado 500 USDT",
    "User:3456 ha retirado 300 USDT",
    "User:2345*** ha retirado 2.000 USDT",
    "User:6789 ha depositado 600 USDT",
    "User:1234*** ha ingresado al mercado",
    "User:1111 ha salido del mercado",
    "User:9994 ha salido del mercado"
  ];

    // Unir todos los mensajes con un separador
    const combinedMessages = messages.join(' ★ ');

    // Calcular la duración en función de la longitud de los mensajes
    const animationDuration = 10 + combinedMessages.length / 10; // Ajusta los valores para cambiar la velocidad
  
    return (
      <div className="tooltip-container tooltip-separator">
        <div className="tooltip-message" style={{ animationDuration: `${animationDuration}s` }}>
          {combinedMessages}
        </div>
      </div>
    );
  };
  
  export default Tooltip;
