import React from 'react';
import { useConnect } from 'wagmi';

export function WalletOptions() {
  const { connectors, connect } = useConnect();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // 3 columnas de igual tamaño
        gap: '10px', // Espacio entre los botones
        maxWidth: '800px', // Ajusta el ancho máximo según tu diseño
        margin: '0 auto', // Centra el contenedor
      }}
    >
      {connectors.map((connector) => (
        <WalletOption
          key={connector.id} // Cambiado a 'id' para asegurar la clave única
          connector={connector}
          onClick={() => connect({ connector })}
        />
      ))}
    </div>
  );
}

function WalletOption({ connector, onClick }) {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  const connectorIcons = {
    MetaMask: 'https://explorer-api.walletconnect.com/v3/logo/md/018b2d52-10e9-4158-1fde-a5d5bac5aa00?projectId=2f05ae7f1116030fde2d36508f472bfb',
    WalletConnect: '/assets/icons/wallets/walletconnect.svg',
    'Trust Wallet': 'https://explorer-api.walletconnect.com/v3/logo/md/7677b54f-3486-46e2-4e37-bf8747814f00?projectId=2f05ae7f1116030fde2d36508f472bfb',
    'Coinbase Wallet': 'https://explorer-api.walletconnect.com/v3/logo/md/a5ebc364-8f91-4200-fcc6-be81310a0000?projectId=2f05ae7f1116030fde2d36508f472bfb',
    Safe: 'https://explorer-api.walletconnect.com/v3/logo/md/3913df81-63c2-4413-d60b-8ff83cbed500?projectId=2f05ae7f1116030fde2d36508f472bfb',
    Injected: '/assets/icons/wallets/injected.svg',
    // Agrega más conectores e iconos según sea necesario
  };

  const buttonStyles = {
    backgroundColor: 'rgba(39, 42, 42, 0.66)',
    cursor: ready ? 'pointer' : 'not-allowed',
    opacity: ready ? 1 : 0.5, // Ajusta la opacidad si no está listo
  };

  const style = {
    ...buttonStyles, // Estilo general para el botón
    width: '100%',
    position: 'relative',
    aspectRatio: '1 / 1',
    backdropFilter: 'blur(20px)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '14px',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  };

  return (
    <button
      disabled={!ready}
      onClick={onClick}
      style={style}
    >
      {/* Agrega el icono aquí */}
      {connectorIcons[connector.name] && (
        <img
          src={connectorIcons[connector.name]}
          alt={`${connector.name} icon`}
          style={{
            width: '64px',
            height: '64px',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '94px', // Asegúrate de que este valor sea el que deseas
            display: 'block', // Asegura que el espacio alrededor se maneje correctamente
            margin: '0 auto', // Centra la imagen si es necesario
            top: '-10px',
            objectFit: 'cover', // Cambiado a camelCase
            zIndex: 50, // Cambiado a camelCase
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px 2px', // Asegúrate de que esté en formato string
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'rgba(255, 255, 255, 0.1)',
          }}
        />
      )}
      <span>{connector.name}</span> {/* Asegura que el nombre esté al lado del icono */}
    </button>
  );
}
