import React, { useState, useEffect, useMemo , useCallback } from 'react';
import NetworkSelector from '../NetworkSelector';
import CouponModal from './CouponModal'; // Importar el modal de cupones
import { handleWithdrawalWithCommission } from '../../wallets/withdraw';
import { useTooltip } from '../ToolTips/TooltipContext';
//TonConnect
import { TonConnectButton, useTonAddress , useIsConnectionRestored, useTonConnectUI } from '@tonconnect/ui-react';
import { useConnect, useAccount, useWalletClient, useSendTransaction  } from 'wagmi'
import { ethers, getBigInt , parseUnits  } from 'ethers';
import { Address, beginCell, toNano } from 'ton-core';
import { BOT_ADDRESS_USDT_TON, BOT_ADDRESS_USDT_ERC20, BOT_ADDRESS_USDT_BSC, BOT_ADDRESS_USDT_TRC20, BOT_ADDRESS_USDT_POLYGON, TON_MEMO } from '../../wallets/config';
import { Account } from '../../wallets/account'
import { WalletOptions } from '../../wallets/wallet-options'
import abi from '../../contract/usdt.json';

const UserWalletModal = ({ isOpen, closeModal, selectedNetwork, handleNetworkChange, saveNetworkPreference, UserisWallet, currentbalance, CommissionRate, isBotOperating, telegramUserId, language }) => {
    const [depositAmount, setDepositAmount] = useState(''); // Estado para la cantidad a depositar
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [gasFee, setGasFee] = useState(0); // Estado para el gas fee
    const [currentCommissionRate, setCurrentCommissionRate] = useState(parseFloat(CommissionRate?.toFixed(2) || 10.0));
    const [isClosing, setIsClosing] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);
    const [isWallet, setIsWallet] = useState(UserisWallet || false);
    const tonAddress = useTonAddress(); 
    const connectionRestored = useIsConnectionRestored();
    const { address, isConnected } = useAccount();
    const { data: walletClient } = useWalletClient();
    const [couponModalOpen, setCouponModalOpen] = useState(false); // Estado para controlar el modal de cupones
    const [selectedCoupon, setSelectedCoupon] = useState(null); // Cupón seleccionado
    const [tonConnectUI] = useTonConnectUI();
    const { connectors, connector } = useConnect()
    const { setTooltipAlert } = useTooltip();
    const { sendTransaction, isPending, isSuccess, data, error } = useSendTransaction();
    


useEffect(() => {
        if (isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
    
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isOpen]);
     
// Manejo de la conexión restaurada para Wagmi
function ConnectWallet() {

    if (isConnected) {
      saveWalletToDB(telegramUserId, selectedNetwork, address); // Guardar la billetera en la base de datos
    }
    else{
      disconnectWallet(); // Desconectar la billetera
    }
 
  // Renderizado condicional basado en el estado de conexión
  return isConnected ? <Account /> : <WalletOptions />;
}

// Manejo de la conexión restaurada para TON

  useEffect(() => {
    const handleWalletConnection = async () => {
      if (selectedNetwork !== 'TON') return; // Solo ejecuta si la red es TON

      try {
        if (connectionRestored && tonAddress) {
          // Si la conexión se ha restaurado correctamente
          setWalletAddress(tonAddress);
          setIsWallet(true);
          saveWalletToDB(telegramUserId, 'TON', tonAddress); // Guardar en la base de datos
          setTooltipAlert('Billetera conectada con éxito.', 'success');
        } else {
          disconnectWallet(); // Desconectar la billetera si no hay conexión o dirección
          setTooltipAlert('Billetera desconectada.', 'success');
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Operación abortada debido a la pérdida de conexión o foco.');
        } else {
          console.error('Error al manejar la conexión de la billetera:', error);
        }
      }
    };

    handleWalletConnection();
  }, [connectionRestored, tonAddress, telegramUserId, selectedNetwork]);

  
  const saveWalletToDB = async (telegramId, network, address) => {
    // Verifica si la billetera ya está conectada
    if (isWallet) {
      console.log('La billetera ya está conectada.');
      return;
    }
  
    try {
      // Guarda la dirección y actualiza el estado de la billetera
      setWalletAddress(address);
      setIsWallet(true);
      setTooltipAlert('Billetera conectada con Éxito.', 'success');
      
      const response = await fetch('/api/connectWallet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ telegramId, network, address }),
      });
  
      // Verifica si la respuesta es exitosa
      if (!response.ok) {
        throw new Error('Error al guardar la billetera en la base de datos');
      }
  
      const data = await response.json();
      console.log('Billetera conectada:', data);
  
    } catch (error) {
      console.error('Error al guardar la billetera en la base de datos:', error);
      setTooltipAlert('Error al conectar la billetera.', 'error'); // Muestra un mensaje de error en el tooltip
      setIsWallet(false); // Restablecer el estado en caso de error
    }
  };
  
  

const disconnectWallet = async () => {
  // Verifica si la billetera ya está desconectada
  if (!isWallet) {
    console.log('La billetera ya está desconectada.');
    return;
  }

  // Resetea el estado de la billetera
  setWalletAddress(null); 
  setIsWallet(false); 

  try {
    const response = await fetch('/api/disconnectWallet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telegramId: telegramUserId, network: selectedNetwork }),
    });

    // Verifica si la respuesta es exitosa
    if (!response.ok) {
      throw new Error('Error al desconectar la billetera en el servidor');
    }

    const data = await response.json();
    console.log('Billetera desconectada:', data);

    // Mensaje de éxito para el usuario
    setTooltipAlert('Billetera desconectada con éxito.', 'success');
  } catch (error) {
    console.error('Error al desconectar la billetera:', error);
    // Mensaje de error para el usuario
    setTooltipAlert('Error al desconectar la billetera.', 'error');
  }
};

  const showWalletConnectButton = useMemo(() => {
    return (
      (selectedNetwork === 'ERC20' ||
        selectedNetwork === 'Polygon' ||
        selectedNetwork === 'BSC') &&
      !walletAddress
    );
  }, [selectedNetwork, walletAddress]);

// Manejar el depósito
const handleDeposit = async () => {
  if (!depositAmount || depositAmount < 10) {
      setTooltipAlert('Por favor, ingresa una cantidad válida. Mínimo 10 USDT', 'warn');
      return;
  }
  try {
      if (selectedNetwork === 'TON') {
          await handleTonDeposit();
      } else if (selectedNetwork === 'ERC20') {
          await handleEthDeposit();
      } else if (selectedNetwork === 'BSC') {
          await handleBscDeposit();
      } else if (selectedNetwork === 'Polygon') {
          await handlePolygonDeposit();
      } else {
          setTooltipAlert('Error durante el proceso de depósito. Reinicia la App.', 'error');
      }
  } catch (error) {
      console.error('Error durante el proceso de depósito:', error); // Agrega este log
      setTooltipAlert('Error durante el proceso de depósito. Inténtalo nuevamente.', 'error');
  }
};


  async function handleTonDeposit() {
       
    if (!walletAddress) {
      setTooltipAlert('La dirección de la billetera TON no es válida.','error');
      return;
    }
    const JettonWalletContractAddress = Address.parse('EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'); // Dirección del contrato Jetton
    const userJettonWalletAddress = Address.parse(walletAddress); // Dirección del Usuario
    const destinationAddress = Address.parse(BOT_ADDRESS_USDT_TON); // Dirección de depósito de Bybit en TON
    const memoTag = TON_MEMO; // Tag o memo personalizado para la transacción
    const depositAmountWithDecimals = depositAmount * (10 ** 6); // Ajusta la cantidad según los decimales del jetton
  
    try {
      // Crear el payload para la transacción
      const forwardPayload = beginCell()
        .storeUint(0, 32)
        .storeStringTail(memoTag)
        .endCell();
  
      // Crear el cuerpo de la transacción
      const body = beginCell()
        .storeUint(0xf8a7ea5, 32) // Código de operación para transferir Jettons
        .storeUint(0, 64) // ID de la consulta (puede ser 0)
        .storeCoins(toNano(depositAmountWithDecimals)) // Monto de USDT en TON
        .storeAddress(destinationAddress) // Dirección de destino (Bybit)
        .storeAddress(userJettonWalletAddress) // Dirección del monedero del usuario
        .storeBit(0) // No rebotar si la transferencia falla
        .storeCoins(toNano("0.02")) // Comisiones de gas en TON
        .storeBit(1) // Indica que hay un payload adicional
        .storeRef(forwardPayload) // Adjuntar el payload
        .endCell();
  
      // Crear la solicitud de transacción
      const transactionRequest = {
        validUntil: Math.floor(Date.now() / 1000) + 360, // Validez de 360 segundos
        messages: [
          {
            address: JettonWalletContractAddress,
            amount: toNano("0.05").toString(),
            payload: body.toBoc().toString("base64"), // Cuerpo codificado en base64
          },
        ], 
      };
       
      // Enviar la transacción con TonConnect
      const result = await tonConnectUI.sendTransaction(transactionRequest);
      setTooltipAlert('Solicitud de pago enviada a tu billetera TON.', 'alert');
  
      if (result.boc) {
        const txID = result.transactionId;
        const blockHash = result.blockHash;
  
        // Enviar los datos del depósito al backend
        const response = await fetch('/api/deposit_usdt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            txID: txID,
            amount: depositAmount,
            toAddress: destinationAddress.toString(),
            chain: 'TON',
            blockHash: blockHash,
            telegram_id: telegramUserId,
          }),
        });
  
        if (!response.ok) {
          console.error('Error en la solicitud al backend:', response.statusText);
          alert('Error al registrar el depósito en el backend.');
        } else {
          setTooltipAlert('Depósito registrado con éxito.','success');
        }
      } else {
        console.error('No se encontró BOC en el resultado de la transacción');
      }
    } catch (error) {
      console.error('Error al enviar la solicitud de pago con TonConnect:', error);
      setTooltipAlert('Error al enviar la solicitud de pago.','error');
      
    }
  }
  
// Lógica para el depósito USDT en ETH  
async function  handleEthDeposit() {
  try {
    if (!isConnected) {
      setTooltipAlert('Conecta tu billetera antes de realizar el depósito.', 'warn');
      return;
    }
    if (!walletClient) {
      setTooltipAlert('Cliente de la billetera no disponible.', 'warn');
      return;
    }
    
    // Clave privada de la billetera del cliente
    const provider  = new ethers.BrowserProvider(walletClient);
    const signer = await provider.getSigner();
   
    // Dirección de la billetera madre
    const motherWalletAddress = BOT_ADDRESS_USDT_ERC20;

    // Crea un contrato para interactuar con USDT
    const usdtContractAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // Dirección del contrato de USDT
    const transferABI = [
      "function decimals() view returns (uint8)",
      "function symbol() view returns (string)",
      "function balanceOf(address a) view returns (uint)"
    ];
    // Contrato del token USDT
    const usdtContract = new ethers.Contract(usdtContractAddress, transferABI, signer);

    
    // Verifica el saldo
    const balance = await provider.getBalance(await signer.getAddress());
    console.log("Saldo disponible:", balance.toString());

    // Enviar USDT a la billetera madre
    const amountToSend = ethers.parseUnits(depositAmount, 6); // 6 decimales para USDT

    // Asegúrate de tener suficientes fondos
    if (balance.valueOf(depositAmount)) {
      
      throw new Error("Saldo insuficiente.");
    }
    const gasFee = await usdtContract.transfer.estimateGas(motherWalletAddress, amountToSend);
    const gasLimit = parseInt(gasFee)

    const tx = await usdtContract.sendTransaction({
      to: motherWalletAddress,
      value: amountToSend
    });
    /*const tx = await usdtContract.transfer(motherWalletAddress, amountToSend, {
      gasLimit: gasLimit,
    });*/
    console.log("Transacción enviada:", tx.hash);
    setTooltipAlert('Enviando transacción...', 'alert');
    // Esperar a que la transacción sea confirmada
    const receipt = await tx.wait();

    if (receipt.status === 1) {
      console.log('Transacción enviada:', receipt.transactionHash);
      setTooltipAlert('Transacción completada con éxito.', 'success');

      // Enviar los detalles de la transacción al backend
      await fetch('/api/deposit_usdt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          txID: receipt.transactionHash,
          amount: depositAmount,
          toAddress: BOT_ADDRESS_USDT_ERC20,
          chain: 'ERC20',
          telegram_id: telegramUserId,
        }),
      });
    } else {
      console.error('Error en la transacción');
      setTooltipAlert('Error en la transacción', 'error');
    }

  } catch (error) {
    console.error('Error al realizar el depósito:', error);
    setTooltipAlert(`Error al realizar el depósito`, 'error');
  }
}

// Lógica para el depósito en BSC  
async function handleBscDeposit() {
  try {
    if (!isConnected) {
      setTooltipAlert('Conecta tu billetera antes de realizar el depósito.', 'warn');
      return;
    }
    if (!walletClient) {
      setTooltipAlert('Cliente de la billetera no disponible.', 'warn');
      return;
    }
    
    // Clave privada de la billetera del cliente
    const provider  = new ethers.BrowserProvider(walletClient);
    const signer = await provider.getSigner();
   
    // Dirección de la billetera madre
    const motherWalletAddress = BOT_ADDRESS_USDT_ERC20;

    // Crea un contrato para interactuar con USDT
    const usdtContractAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // Dirección del contrato de USDT

    // Contrato del token USDT
    const usdtContract = new ethers.Contract(usdtContractAddress, abi, signer);

    
    // Verifica el saldo
    const balance = await provider.getBalance(await signer.getAddress());
    console.log("Saldo disponible:", balance.toString());

    // Enviar USDT a la billetera madre
    const amountToSend = ethers.parseUnits(depositAmount, 6); // 6 decimales para USDT

    // Asegúrate de tener suficientes fondos
    if (balance.valueOf(depositAmount)) {
      
      throw new Error("Saldo insuficiente.");
    }
    const gasFee = await usdtContract.transfer.estimateGas(motherWalletAddress, amountToSend);
    const gasLimit = parseInt(gasFee)

    const tx = await usdtContract.transfer(motherWalletAddress, amountToSend, {
      gasLimit: gasLimit,
    });
    console.log("Transacción enviada:", tx.hash);
    setTooltipAlert('Enviando transacción...', 'alert');
    // Esperar a que la transacción sea confirmada
    await tx.send()
    const receipt = await tx.wait();

    if (receipt.status === 1) {
      console.log('Transacción enviada:', receipt.transactionHash);
      setTooltipAlert('Transacción completada con éxito.', 'success');

      // Enviar los detalles de la transacción al backend
      await fetch('/api/deposit_usdt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          txID: receipt.transactionHash,
          amount: depositAmount,
          toAddress: BOT_ADDRESS_USDT_ERC20,
          chain: 'ERC20',
          telegram_id: telegramUserId,
        }),
      });
    } else {
      console.error('Error en la transacción');
      setTooltipAlert('Error en la transacción', 'error');
    }

  } catch (error) {
    console.error('Error al realizar el depósito:', error);
    setTooltipAlert(`Error al realizar el depósito`, 'error');
  }
}
// Lógica para el depósito en POLYGON  
async function  handlePolygonDeposit() {
  try {
    if (!isConnected) {
      setTooltipAlert('Conecta tu billetera antes de realizar el depósito.', 'warn');
      return;
    }

     // Convertir el monto de USDT a 6 decimales
     const amount = parseUnits(depositAmount, 6);

     // Enviar transacción
     sendTransaction({
      to: BOT_ADDRESS_USDT_POLYGON, // Dirección de destino
      value: amount, // Cantidad de USDT a enviar
      chainId: 137, // Polygon chainId
    });
       
    // Si está en proceso
    if (isPending) {
      setTooltipAlert('Enviando transacción...', 'alert');
    }
 // Si la transacción fue exitosa
 if (isSuccess && data) {
  console.log('Transacción enviada:', data.hash);
  setTooltipAlert('Transacción completada con éxito.', 'success');

  // Enviar los detalles de la transacción al backend
  await fetch('/api/deposit_usdt', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      txID: data.hash,
      amount: depositAmount,
      toAddress: BOT_ADDRESS_USDT_POLYGON,
      chain: 'Polygon',
      telegram_id: telegramUserId,
    }),
  });
}

// Si hay error
if (error) {
  console.error('Error al enviar USDT en Polygon:', error);
  setTooltipAlert(`Error al enviar USDT en Polygon: ${error.message}`, 'error');
}

  } catch (err) {
    console.error('Error al realizar el depósito:', err);
    setTooltipAlert(`Error al realizar el depósito: ${err.message}`, 'error');
  }
 }
// Lógica para el depósito en TRC20 
 /*async function  handleTrc20Deposit() {
    try {
      // Dirección del contrato USDT en TRC20
      const usdtContractAddress = 'TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9'; // USDT en TRC20
  
      // Convertir el monto de USDT a base 6 decimales (USDT tiene 6 decimales)
      const depositAmountInTRC20 = tronWeb.toSun(depositAmount); // Convierte la cantidad a SUN (unidad mínima en TRON)
  
      // Obtener la cuenta conectada (en caso de usar TronLink)
      const userAddress = tronWeb.defaultAddress.base58;
  
      // Verificar si hay suficiente TRX para pagar el gas
      const trxBalance = await tronWeb.trx.getBalance(userAddress);
      const gasPrice = await tronWeb.trx.getBandwidthPrice(); // En TRON, el gas se paga con ancho de banda
  
      if (trxBalance < gasPrice) {
        throw new Error('Fondos insuficientes para pagar las comisiones de gas en TRX');
      }
  
      // Instanciar el contrato USDT en TRC20
      const usdtContract = await tronWeb.contract().at(usdtContractAddress);
  
      // Realizar la transacción para transferir USDT
      const tx = await usdtContract.transfer(BOT_ADDRESS_USDT_TRC20, depositAmountInTRC20).send({
        feeLimit: 1000000, // Límite de comisiones en TRX (1 TRX)
        callValue: 0, // No enviar TRX, solo tokens
      });
  
      console.log('Transacción enviada:', tx);
  
      // Esperar la confirmación de la transacción
      const receipt = await tronWeb.trx.getTransactionInfo(tx);
      if (receipt.result) {
        alert('Transacción completada con éxito');
  
        // Enviar los detalles de la transacción al backend
        await fetch('/api/deposit_usdt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            txID: tx.hash,
            amount: depositAmount,
            toAddress: BOT_ADDRESS_USDT_TRC20,
            chain: 'TRC20',
            blockHash: '',
            telegram_id: telegramUserId
          })
        });
      } else {
        console.error('Error en la confirmación de la transacción');
      }
  
    } catch (error) {
      console.error('Error al enviar USDT en TRC20:', error);
      alert(`Error al enviar USDT en TRC20: ${error.message}`);
    }
  }*/


  
// Validar y manejar el retiro
const handleWithdraw = () => {
    if (withdrawAmount <= 10 || withdrawAmount > currentbalance) {
      setTooltipAlert('La cantidad a retirar no es válida o excede el saldo disponible.', 'warn'); // Muestra el mensaje en el tooltip
      return;
    }
    else if (!isWallet)
    {
      setTooltipAlert('No tienes una Billetera conectada', 'warn'); // Muestra el mensaje en el tooltip
      return;
    }

    const netAmount = calculateFinalAmount(withdrawAmount); // Calcular el monto neto
    setTooltipAlert(`Monto neto a recibir: ${netAmount} USDT`, `alert`); // Muestra el mensaje en el tooltip

    handleWithdrawalWithCommission(netAmount, walletAddress, selectedNetwork, currentCommissionRate, isBotOperating);
  };
  // Calcular monto final para el retiro
const calculateFinalAmount = (withdrawAmount) => {
    const commissionAmount = (withdrawAmount * currentCommissionRate) / 100;
    const finalAmount = withdrawAmount - commissionAmount - gasFee;
    return finalAmount > 0 ? finalAmount.toFixed(2) : 0; // Asegurarse de que el monto no sea negativo
};  
  // Aplicar el cupón seleccionado
const applyCoupon = (coupon) => {
    const newCommissionRate = (currentCommissionRate - coupon.discount).toFixed(2); // Aplicar el descuento
    setCurrentCommissionRate(parseFloat(newCommissionRate));
    setSelectedCoupon(coupon); // Guardar cupón seleccionado
    setCouponModalOpen(false); // Cerrar el modal después de seleccionar el cupón
};

const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300); // Duración de la animación
  };

  if (!isOpen && !isClosing) return null;

return (
    <>
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
    <div
    className={`modal-content bg-black p-6 shadow-lg rounded-lg relative transition-transform duration-300 ease-in-out ${
      isClosing ? 'animate-slideDown' : 'animate-slideUp'
    }`}
    style={{ maxHeight: '90vh', width: '100%', maxWidth: '600px' }}
  >
          {/* Mostrar la dirección de la billetera conectada */}
          {isWallet ?   (
             <>
    {/* Hacer que el header esté completamente fijo */}
   <div className="modal-header flex justify-between items-center mb-4 sticky bg-black p-6 z-10" style={{ position: 'sticky', top: -30 }}>
    {/* Título centrado */}
    
    <h2 className="text-xl font-bold mb-4 w-full">Billetera Conectada</h2>
    {/* Botón de cerrar en la esquina superior derecha */}
    <button
        onClick={handleClose}
        className="absolute top-2 right-2 text-gray-400 hover:text-white"
      >
        <i className="fas fa-times text-xl"></i>
      </button>
            </div>
            <div className="mt-4">
      <div className="flex justify-center items-center"> {/* Cambiado a justify-center y items-center */}
      {selectedNetwork === 'TON' && <TonConnectButton
      style={{
        width: '50%',
        height: '50px',
        position: 'relative',
        overflow: 'hidden',     
        display: 'block', // Asegura que el espacio alrededor se maneje correctamente
        margin: '0 auto', // Centra la imagen si es necesario
        objectFit: 'cover', // Cambiado a camelCase
       
      }}
      />}
        {showWalletConnectButton &&     
        <ConnectWallet />       
       
        
        }
      </div>
    </div>
  {/* Sección de saldo USDT*/}
  <div className="mb-6 bg-gray-800 p-4 rounded-lg shadow-lg">
    <div className="flex justify-between items-center">
    <div className="flex items-center mb-2">
  <i className="fas fa-money-bill-wave mr-2"></i>
  <label className="text-white text-lg font-semibold">Saldo actual USDT:</label>
</div>
<div className="bg-gray-900 p-4 rounded-lg text-white text-xl">
  {new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(currentbalance)}
</div>
    </div>
  </div>
  {/* Sección de saldo OptiCoin
  <div className="mb-6 bg-gray-800 p-4 rounded-lg shadow-lg">
    <div className="flex justify-between items-center">
    <div className="flex items-center mb-2">
  <i className="fas fa-money-bill-wave mr-2"></i>
  <label className="text-white text-lg font-semibold">Saldo actual OptiCoin:</label>
</div>
<div className="bg-gray-900 p-4 rounded-lg text-white text-xl">
  {new Intl.NumberFormat('en',).format(currentbalance)}
</div>
    </div>
  </div>*/}
{/* Sección de depósito */}
<div className="mb-8 p-6">
  <label className="block mb-2 text-white text-lg font-semibold">
    Depositar USDT <span className="text-sm font-normal">(Red: {selectedNetwork})</span>
  </label>
  <div className="flex items-center space-x-3">
    <input
      type="number"
      className="bg-gray-700 p-3 rounded-lg w-full text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
      placeholder="Ingresa el monto"
      value={depositAmount}
      onChange={(e) => setDepositAmount(e.target.value)}
    />
    <button
      onClick={handleDeposit}
      className="bg-orange-500 hover:bg-orange-600 px-6 py-3 rounded-lg text-white text-lg font-semibold"
    >
      Depositar
    </button>
  </div>
</div>

{/* Sección de retiro */}
<div className="mb-8 p-6">
  <label className="block mb-2 text-white text-lg font-semibold">
    Retirar USDT  <span className="text-sm font-normal">(Red: {selectedNetwork})</span>
    </label>
  <div className="flex items-center space-x-3">
    <input
      type="number"
      className="bg-gray-700 p-3 rounded-lg w-full text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
      placeholder="Ingresa el monto"
      value={withdrawAmount}
      onChange={(e) => setWithdrawAmount(e.target.value)}
    />
    <button
      onClick={() => handleWithdraw()}
      className="bg-orange-500 hover:bg-orange-600 px-6 py-3 rounded-lg text-white text-lg font-semibold"
    >
      Retirar
    </button>
  </div>

  {/* Mostrar detalles de comisión, gas y monto final */}
  {withdrawAmount && (
    <div className="text-white text-sm mt-6 space-y-4">
      <div className="flex justify-between">
        <p>Comisión OptiGain:</p>
        <span className="text-orange-500">{currentCommissionRate} %</span>
      </div>
      <div className="flex justify-between">
        <p>Fee Red {selectedNetwork}:</p>
        <span className="text-orange-500">0</span>
      </div>
      <div className="flex justify-between mt-4">
        <p className="text-lg font-semibold">Monto final a recibir:</p>
        <span className="text-green-500 text-lg">{new Intl.NumberFormat('en').format(calculateFinalAmount(withdrawAmount))} USDT</span>
      </div>
      <button
        onClick={() => setCouponModalOpen(true)}
        className="mt-6 bg-orange-500 hover:bg-orange-600 px-4 py-3 rounded-lg w-full text-white text-sm"
      >
        Usar Cupón
      </button>
    </div>
  )}
</div>

</>
            
          ) : ( <div>
            <div className="modal-header flex justify-between items-center mb-4 sticky bg-black p-6 z-10" style={{ position: 'sticky', top: -30 }}>
              {/* Título centrado */}
              <h2 className="text-xl text-orange-500 text-center w-full">Connect Your Wallet</h2>
              {/* Botón de cerrar en la esquina superior derecha */}
              <button onClick={handleClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
                <i className="fas fa-times text-xl"></i>
              </button>
            </div>
        
<div className="space-y-6 text-center"> {/* Añadido text-center aquí */}
  {/* Siempre mostrar el NetworkSelector si no hay una red seleccionada */}
  <NetworkSelector
    selectedNetwork={selectedNetwork}
    handleNetworkChange={handleNetworkChange}
    saveNetworkPreference={saveNetworkPreference}
  />

  {/* Mostrar botones de conexión si hay una red seleccionada */}
  {selectedNetwork && (
    <>
      <p className="text-black p-4">Selecciona tu billetera para {selectedNetwork}</p>
      
      <div className="flex justify-center items-center"> {/* Cambiado a justify-center y items-center */}
      {selectedNetwork === 'TON' && <TonConnectButton
      style={{
        width: '50%',
        height: '50px',
        position: 'relative',
        overflow: 'hidden',     
        display: 'block', // Asegura que el espacio alrededor se maneje correctamente
        margin: '0 auto', // Centra la imagen si es necesario
        objectFit: 'cover', // Cambiado a camelCase
       
      }}
      />}

        {showWalletConnectButton && 
         <ConnectWallet /> 
        }
      </div>
    </>
  )}
</div>


            <>
  {/* Sección de saldo */}
  <div className="mb-6 bg-gray-800 p-4 rounded-lg shadow-lg">
    <div className="flex justify-between items-center">
    <div className="flex items-center mb-2">
  <i className="fas fa-money-bill-wave mr-2"></i>
  <label className="text-white text-lg font-semibold">Saldo actual:</label>
</div>
<div className="bg-gray-900 p-4 rounded-lg text-white text-xl">
  {new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(currentbalance)} USDT
</div>
    </div>
  </div>

  {/* Mensaje de conexión de wallet */}
  <div className="mt-4 p-4 bg-red-600 text-white rounded-lg text-center">
    <p className="text-lg font-medium">
      Debes conectar tu wallet para depositar o retirar USDT.
    </p>
  </div>
  
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    {/* Aquí puedes agregar botones o componentes si es necesario */}
  </div>
</>

          </div>)}
    </div>
    </div>
            {/* Modal de cupones */}
            {couponModalOpen && (
        <CouponModal applyCoupon={applyCoupon} closeModal={() => setCouponModalOpen(false)} />
      )}
    </>
)

};
export default UserWalletModal;
