import React, { useState } from 'react';
import translations from './languages'; // Import the translations

const Tooltip = ({ message, visible, position }) => {
  return (
    <div
      className={`tooltip ${visible ? 'visible' : ''}`}
      style={{
        position: 'absolute',
        top: position.y - 30, // Ajusta la posición Y hacia arriba
        left: position.x,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '8px',
        borderRadius: '4px',
        zIndex: 1000,
        transition: 'opacity 0.3s ease',
        opacity: visible ? 1 : 0, // Controla la visibilidad
      }}
    >
      {message}
    </div>
  );
};

const Footer = ({ toggleTradingView, toggleUserWalletModal, toggleEventModal, openNotificationModal,  language }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 40, y: 0 });
  const t = translations[language];

 const handleClick = (event) => {
  const { clientX, clientY } = event;
   // Dimensiones de la ventana y del tooltip
   const tooltipWidth = 150;  // Ajusta este valor al ancho estimado del tooltip
   const tooltipHeight = 50;  // Ajusta este valor a la altura estimada del tooltip
   const windowWidth = window.innerWidth;
   const windowHeight = window.innerHeight;

   let x = clientX;
   let y = -10;

    // Ajuste horizontal (para que no se salga a la derecha o izquierda)
    if (clientX + tooltipWidth > windowWidth) {
      x = windowWidth - tooltipWidth - 10;  // 10px de margen
    } else if (clientX < 10) {
      x = 10; // Mínimo margen a la izquierda
    }

    // Ajuste vertical (para que no se salga por debajo o arriba)
    if (clientY + tooltipHeight > windowHeight) {
      y = windowHeight - tooltipHeight - 10;  // 10px de margen
    } else if (clientY < 10) {
      y = 10; // Mínimo margen arriba
    }

    setTooltipPosition({ x, y });
    setTooltipVisible((prev) => !prev);

    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  return (
    <footer className="footer text-white p-2 flex justify-between fixed inset-x-4 bottom-4 z-50 rounded-lg shadow-neon-top">
      <button onClick={toggleTradingView} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-chart-line text-lg"></i>
        <span className="mt-1 text-sm">{t.trading}</span>
      </button>
      
      {/* Botón de Events, llamará a la función openEventModal */}
      <button onClick={toggleEventModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-clock text-lg"></i>
        <span className="mt-1 text-sm">{t.events}</span>
      </button>
      
      <button onClick={toggleUserWalletModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-wallet text-lg"></i>
        <span className="mt-1 text-sm">{t.wallet}</span>
      </button>
      
      <button onClick={handleClick}  className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-dollar-sign text-lg"></i>
        <span className="mt-1 text-sm">{t.earn}</span>
      </button>
      
      <button onClick={handleClick}  className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
      <i className="fas fa-store text-lg"></i>
      <span className="mt-1 text-sm">{t.store}</span>
      </button>

      {tooltipVisible && (
        <Tooltip
          message="Próximamente"
          visible={tooltipVisible}
          position={tooltipPosition}
        />
      )}
    
      
      {/*<button onClick={openNotificationModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-bell text-lg"></i>
        <span className="mt-1 text-sm">{t.notify}</span>
      </button>*/}
    </footer>
  );
};

export default Footer;
