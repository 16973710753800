import React, { useState, useEffect } from 'react';
import { useTooltip } from './ToolTips/TooltipContext';
const NetworkSelector = React.memo(({ selectedNetwork, handleNetworkChange, saveNetworkPreference }) => {
  const [selected_Network, setSelectedNetwork] = useState('');
  const { setTooltipAlert } = useTooltip();
  const [tooltipShown, setTooltipShown] = useState(false); // Estado para controlar la visualización del tooltip

  const handle_NetworkChange = (network) => {
    setSelectedNetwork(network);
    console.log(`Red seleccionada manualmente: ${network}`);
  };

  useEffect(() => {
    // Solo mostrar el tooltip si no se ha mostrado antes
    if (!tooltipShown) {
      let message = '';
      switch (selected_Network) {
        case 'BSC':
          message = 'USDT de la Red Binance Chain';
          break;
        case 'ERC20':
          message = 'USDT de la Red Ethereum';
          break;
        case 'TRC20':
          message = 'USDT de la Red Tron';
          break;
        case 'Polygon':
          message = 'USDT de la Red Polygon';
          break;
        case 'TON':
          message = 'USDT de la Red TON';
          break;
        default:
          message = '';
      }

      if (message) {
        setTooltipAlert(message, 'success');
        setTooltipShown(true); // Marca que se ha mostrado el tooltip
      }
    }
  }, [selected_Network, setTooltipAlert, tooltipShown]); // Agrega tooltipShown como dependencia

  return (

    <div className="p-6 bg-gray-800 text-white rounded-lg shadow-lg text-center">
      <h2 className="text-xl text-orange-500 text-center  w-full">Seleccionar Red</h2>
      {/* Selector de red */}
      <select
        className="w-full p-2 bg-gray-700 text-white rounded-lg mb-4"
        value={selectedNetwork}
        onChange={(e) => {
          const network = e.target.value;
          handleNetworkChange(network); // Actualizar red seleccionada
          handle_NetworkChange(network);
          saveNetworkPreference(network); // Guardar preferencia de red
          setTooltipShown(false);
        }}
      >
        <option value="">Selecciona tu red</option>
        <option value="BSC">Binance Chain (BEP2)</option>
        <option value="ERC20">Ethereum (ERC20)</option>
        <option value="TRC20">Tron (TRC20)</option>
        <option value="Polygon">Polygon (MATIC)</option>
        <option value="TON">TON</option>      
      </select>

      {/* Mostrar advertencia si no hay red seleccionada */}
      {!selectedNetwork && (
        <p className="text-yellow-400 mt-4">Por favor selecciona una red para continuar.</p>
      )}

    </div>      

  );
});

export default NetworkSelector;
