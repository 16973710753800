import { http, createConfig } from 'wagmi'
import { polygon, bsc, mainnet, tron } from 'wagmi/chains'
import { coinbaseWallet, injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

const projectId = 'c47d64e61a16712fa776c251da3d6232'

export const config = createConfig({
  chains: [polygon, bsc, mainnet, tron],
  ssr: true,
  connectors: [
    injected(),
    walletConnect({ projectId }),
    metaMask(),
    safe(),

  ],
  transports: {
    [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/CyDdzGMN8MX5laJ1og2h3s7eZtoA3S4M'),
    [bsc.id]: http(),
    [tron.id]: http(),
    [polygon.id]: http('https://polygon-mainnet.g.alchemy.com/v2/CyDdzGMN8MX5laJ1og2h3s7eZtoA3S4M'),
  },
})