// Importar configuraciones desde el archivo config.js
import { COMMISSION_ADDRESS, COMMISSION_NETWORK } from './config';
const axios = require('axios');

// Función para manejar el retiro con comisión
export async function handleWithdrawalWithCommission(withdrawalAmount, userAddress, selectedNetwork, commissionRate, is_BotOperating) {
    try {
      // 1. Verificar si el bot esta en operacion
      if (is_BotOperating) {
        console.log('El Bot esta en operacion, espera a que termine');
        return;
      }
      // 2. Calcular la comisión usando el commissionRate pasado como parámetro
      const commissionAmount = withdrawalAmount * commissionRate;
      const finalWithdrawalAmount = withdrawalAmount - commissionAmount;
      console.log(`Monto a retirar: ${finalWithdrawalAmount} USDT, Comisión: ${commissionAmount} USDT`);
  
      // 3. Procesar el retiro al usuario
      console.log('Procesando retiro de USDT al usuario...');
      await withdrawUSDT(userAddress, finalWithdrawalAmount, selectedNetwork);

      const transactionFee = await getWithdrawalFee('USDT', selectedNetwork);
      const netAmount = commissionAmount - transactionFee;
      let finalCommissionAmount = netAmount;

      // 4. Si el commissionRate es mayor que 3%, dividir la comisión
      if (commissionRate >= 0.03) {
        const twoPercentCommission = netAmount * 0.02; // Ajuste al 2% de la comisión
        finalCommissionAmount = netAmount - twoPercentCommission;
        // Convertir el 2% de la comisión a BNB y transferirlo a la billetera de futuros
        console.log(`Convirtiendo ${twoPercentCommission} USDT a BNB...`);
        console.log(`Pagando comision...`);
        await buyBNB(twoPercentCommission);
  
        console.log(`Restante comisión de ${finalCommissionAmount} USDT enviada a la dirección de comisión.`);
        await withdrawUSDTForComission(COMMISSION_ADDRESS, finalCommissionAmount, COMMISSION_NETWORK);
      } else {
        // 6. Si el commissionRate es menor o igual a 3%, enviar toda la comisión normalmente
        console.log('Procesando comisión...');
        await withdrawUSDTForComission(COMMISSION_ADDRESS, finalCommissionAmount, COMMISSION_NETWORK);
      }

    } catch (error) {
      console.error('Error al manejar la solicitud de retiro con comisión:', error);
    } finally {
    console.log(`Retiro de USDT Exitoso...`);
    
    }
  }
  
// Función para convertir USDT a BNB
export async function buyBNB(amountUSDT) {
    try {
      const response = await axios.post('/api/buybnb', {
        amountUSDT
      });
  
      console.log(`Conversión de ${amountUSDT} USDT a BNB exitosa:`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error al convertir USDT a BNB:', error.response?.data || error.message);
      throw error;
    }
  }

// Función para realizar el retiro de USDT
export async function withdrawUSDT(address, amount, network) {
    try {
      const response = await axios.post('/api/withdraw', {
        address,
        amount,
        network
      });
  
      console.log(`Retiro de ${amount} USDT exitoso:`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error al procesar el retiro:', error.response?.data || error.message);
      throw error;
    }
  }

// Función para realizar el retiro de USDT desde la cuenta spot a la dirección del bot
export async function withdrawUSDTForComission(address, amount, network) {
    try {
        const response = await axios.post('/api/withdraw-bot', {
            address,
        amount,
        network
      });
  
      console.log(`Retiro de ${amount} USDT a la dirección ${address} en la red ${network} exitoso:`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error al procesar el retiro:', error.response?.data || error.message);
      throw error;
    }
  }
  
// Función para obtener la comisión de retiro usando la API REST de Binance
export async function getWithdrawalFee(asset, network) {
    try {
      const response = await axios.get('/api/withdrawal-fee', {
        params: { asset, network }
      });
  
      console.log(`La comisión de retiro para ${asset} en la red ${network} es:`, response.data.withdrawFee);
      return response.data.withdrawFee;
    } catch (error) {
      console.error('Error al obtener la comisión de retiro:', error.response?.data || error.message);
      throw error;
    }
  }



